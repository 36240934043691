.primary-btn {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  &::after {
    height: 100%;
    left: 0;
    top: 0;
    width: 0;
  }
}

.ant-btn {
  height: auto;
}
