:root {
  // colors
  --primary: #6929c4;
  --app-secondary: #ee3566;
  --app-error: #ff4d4f;
  --link: #1677ff;

  --white: #fff;
  --bg-blue: #eff6fe;

  --black: #000000;
  --black-text-base: #222222;
  --gray-text: #838383;
  --gray-50: #f6f6f6;
  --gray-100: #babfc7; // --gray-lightest
  --gray-200: #ededed; // --gray-lighter
  --gray-300: #dae1e7; // --gray-light
  --gray-400: #636363;
  --gray-450: #646464;
  --gray-500: #adb5bd;
  --gray-600: #b8c2cc; // --gray
  --gray-700: #4e5154;
  --gray-800: #1e1e1e; // --gray-dark
  --gray-900: #2a2e30;

  --green-200: #56d179;
  // Add more custom properties as needed

  // landing Page V2
  --font-family-v2: 'Mulish', sans-serif;

  --purple-darker: #4a1596;
  --purple-normal: #6929c4;
  --purple-light: #e9dff6;
  --purple-lighter: #f7f4fc;

  --neutral-100: #000000;
  --neutral-200: #404040;
  --neutral-300: #bfbfbf;
  --neutral-400: #e5e5e5;
  --neutral-500: #f4f4f4;
  --neutral-600: #ffffff;

  // AAS Admin

  --aas-palette-text-primary: rgba(47, 43, 61, 0.9);
  --aas-palette-action-hover: rgba(47, 43, 61, 0.06);
  --aas-palette-text-secondary: rgba(47, 43, 61, 0.7);
  --aas-palette-divider: rgba(47, 43, 61, 0.12);
  --aas-border-color: rgb(47 43 61 / 0.12);
  --aas-shape-customBorderRadius-sm: 4px;
  --aas-palette-primary-main: #7367f0;
  // Add more custom properties as needed
}

@font-face {
  font-family: 'Mulish';
  src: url('@core/fonts/Mulish/Mulish-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
