.ant-select-dropdown {
  z-index: 1350 !important;
}
.ant-form {
  .ant-select {
    height: auto;
  }
  .ant-form-item {
    margin-bottom: 1.1rem;
    .ant-form-item-label {
      padding: 0 !important;
    }
    label {
      color: var(--gray-text);
      font-size: 1rem;
    }
    .ant-form-item-control {
      .ant-input {
        height: 2.1875rem;
        padding: 0.4688rem 0.8125rem;
      }
      textarea {
        height: auto !important;
      }
      .ant-select-selector {
        height: 2.1875rem;
      }

      .ant-form-item-explain-error {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap !important;
        width: 100%;
        margin: 0 0.1rem;
        color: var(--app-error);
        font-size: 0.75rem;

        &:hover {
          /* Disable the text overflow on hover */
          text-overflow: unset;
          /* Set the white-space to normal on hover */
          white-space: normal !important;
          /* Set a higher z-index to ensure the full text appears above other elements */
          z-index: 1;
        }
      }
      input {
        font-size: 1rem;
        color: var(--black);
      }
      .ant-input-password {
        padding: 0.4688rem 0.8125rem;
        input {
          padding: 0;
          height: auto;
        }
      }
    }
  }
  .PhoneInput {
    padding: 0px 11px;
    border: 1px solid #d9d9d9;
    background-color: white;
    border-radius: 6px;
    .PhoneInputInput {
      border: none;
      width: 100%;
      height: 2.1875rem;
      padding: 0.12px 12px;
    }
  }
}
