.ant-drawer {
  z-index: 1100;
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0px 4px 18px rgb(47 43 61 / 0.16);

  .ant-drawer-title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5556;
  }
  .ant-drawer-extra {
    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 6px rgb(115 103 240 / 0.3);
      cursor: pointer;
      font-weight: 500;
      font-size: 0.9375rem;
      line-height: 1.46667;
      text-transform: none;
      border-radius: 6px;
      transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      padding: 0.5rem 1.25rem;
      height: auto;
    }
  }
}
.ant-drawer-mask {
  background-color: rgb(47 43 61 /0.5) !important;
}

@media only screen and (max-width: 576px) {
  .ant-drawer .ant-drawer-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
}
