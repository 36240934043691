[data-floating-ui-safe-polygon] .scrollbar-container {
  pointer-events: auto;
}
[data-floating-ui-safe-polygon] .scrollbar-container .ps__rail-y {
  pointer-events: none;
}

.ps__rail-y {
  inset-inline-end: 0 !important;
  inset-inline-start: auto !important;
  & .ps__thumb-y {
    inset-inline-end: 3px !important;
    inset-inline-start: auto !important;
    background-color: rgba(47, 43, 61, 0.12);
    inline-size: 6px;
    &:hover,
    &:focus,
    &.ps--clicking {
      background-color: rgb(47 43 61 / 0.3) !important;
    }
  }
  &:hover,
  &:focus,
  &.ps--clicking {
    background-color: rgba(47, 43, 61, 0.06) !important;
  }
  &:hover,
  &:focus,
  &.ps--clicking {
    .ps__thumb-y {
      background-color: rgb(47 43 61 / 0.3) !important;
    }
  }
}

.ts-vertical-nav-root {
  .ps__thumb-y {
    inline-size: 4px;
    &:hover,
    &:focus,
    &.ps--clicking {
      inline-size: 6px;
    }
  }
  .ps__rail-y {
    inline-size: 10px;
    &:hover,
    &:focus,
    &.ps--clicking {
      background-color: transparent !important;
      .ps__thumb-y {
        inline-size: 6px;
      }
    }
  }
}
