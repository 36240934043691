@use 'fonts/fonts';
@use 'button/button';
@use 'tooltip/tooltip';
@use 'form/form.scss';
@use 'select/select.scss';
@use 'drawer/drawer';

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.ant-notification .ant-notification-notice {
  padding: 0.625rem;

  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
    .anticon {
      font-size: 1rem;
    }
    .ant-notification-notice-message {
      margin-inline-start: 0px;
      padding-inline-end: 0px;
    }
    .ant-notification-notice-description {
      margin-inline-start: 1.5rem;
    }
  }
  .ant-notification-notice-close {
    top: 0.625rem;
  }
}

.text-dotted {
  display: -webkit-box;
  max-width: 100%;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-dotted-2 {
  display: -webkit-box;
  max-width: 100%;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-dotted-3 {
  display: -webkit-box;
  max-width: 100%;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ant-tooltip {
  max-width: 31.25rem !important;
  .ant-tooltip-inner {
    display: -webkit-box;
    max-width: 100%;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.info-section-message {
  padding: 0.7rem;
  border-radius: 0.625rem;
  background-color: #eff6fe;
  display: flex;
  align-items: center;
  .info-text {
    color: #0a61d0;
    font-size: 1rem;
    font-weight: 600;
  }
}

.p-relative {
  position: relative;
}
