/**
 * This file is part of [Nainik | Software Developer]
 *
 * (c) 2021 [Nainik Mehta] <[nainikmehta25@gmail.com]>
 *
 * --------------------------------------------------
 *
 * @module app.v1.nainikApp
 * @description Base Css File
 * @author [Nainik Mehta] <[https://www.linkedin.com/in/nainik-mehta-25nk12/]>
 * @version 1.0.0
 *
 * --------------------------------------------------
 */

@import 'antd/dist/reset.css';
@import 'aos/dist/aos.css';
@import 'react-phone-number-input/style.css';
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'react-perfect-scrollbar/dist/css/styles.css';

@import '@core/scss/core.scss';

@font-face {
  font-family: 'Mulish';
  src: url('@core/fonts/Mulish/Mulish-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--neutral-200);
}

body,
.ant-menu {
  margin: 0 !important;
  font-family: 'Mulish', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black-text-base);
  font-size: 1rem;
  font-weight: 400;
  padding: 0;
}

html {
  display: flex;
  inline-size: 100%;
  min-block-size: 100%;
  scroll-behavior: smooth;
  font-family: 'Mulish', sans-serif !important;
}

ul:not([class]) {
  margin-block: 1rem;
  padding-inline-start: 40px;
}

.heading-3x {
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
}
.heading-2x {
  font-weight: bold;
  font-size: 44px;
  line-height: 64px;
}
.heading-x {
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
}
.heading-l {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}
.heading-n {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.heading-s {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.body-1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}
.body-2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.body-3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.title-1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.ant-input:hover,
.ant-input-focused,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused,
.ant-input-password:hover,
.ant-input-password-focused,
.ant-select,
.ant-select:hover,
.ant-select-focused,
.ant-select-selector:hover {
  border-color: var(--purple-normal) !important; // Custom primary color
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--purple-normal) !important; // Active tab text color
}
.ant-tabs-ink-bar {
  background-color: var(
    --purple-normal
  ) !important; // Primary color for the ink bar
}

.ant-tabs-tab {
  &:hover {
    color: var(--purple-normal) !important; // Primary color for hover
  }
}

// Ensure active tab also has hover color when hovered
.ant-tabs-tab.ant-tabs-tab-active:hover .ant-tabs-tab-btn {
  color: var(
    --purple-normal
  ) !important; // Maintain primary hover color for the active tab
}

// Checkboxes
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(
    --purple-normal
  ) !important; // Primary color for checked state
  border-color: var(--purple-normal) !important;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(
    --purple-normal
  ) !important; // Hover and focus state for checkbox
}

// Radio Buttons
.ant-radio-checked .ant-radio-inner {
  border-color: var(--purple-normal) !important; // Checked border color
  background-color: var(--purple-normal) !important; // Checked border color
}

.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(
    --purple-normal
  ) !important; // Hover and focus state for radio button
}

.ant-radio-wrapper {
  &:hover {
    .ant-radio-inner {
      border-color: var(
        --purple-normal
      ) !important; // Primary color for the radio button border on hover
    }
  }
}

.ant-btn-variant-outlined {
  &:hover {
    color: var(--purple-normal) !important;
    border-color: var(--purple-normal) !important;
  }
}

.ant-rate-star-full {
  svg {
    color: var(--purple-normal); // Custom color for full stars
  }
}
.ant-rate-star-half {
  .ant-rate-star-first {
    svg {
      color: var(--purple-normal); // Custom color for half stars
    }
  }
  .ant-rate-star-second {
    svg {
      color: var(--neutral-300); // Custom color for half stars
    }
  }
}
.ant-rate-star-zero {
  svg {
    color: var(--neutral-300); // Custom color for empty stars
  }
}

/* Mobile devices (max-width: 576px) */
@media (max-width: 576px) {
  .heading-3x {
    font-size: 24px;
    line-height: 32px;
  }
  .heading-2x {
    font-size: 24px;
    line-height: 32px;
  }
  .heading-x {
    font-size: 26px;
    line-height: 32px;
  }

  .heading-n {
    font-size: 14px;
    line-height: 22px;
  }
  .heading-s {
    font-size: 12px;
    line-height: 18px;
  }
  .body-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .heading-l,
  .body-2,
  .body-3,
  .title-1 {
    font-size: 16px;
    line-height: 24px;
  }
}

/* Tablets (min-width: 577px and max-width: 768px) */
@media (min-width: 577px) and (max-width: 768px) {
  .heading-3x {
    font-size: 50px;
    line-height: 66px;
  }
  .heading-2x {
    font-size: 30px;
    line-height: 45px;
  }
  .heading-x {
    font-size: 26px;
    line-height: 32px;
  }
  .heading-l {
    font-size: 18px;
    line-height: 34px;
  }
  .heading-n {
    font-size: 14px;
    line-height: 22px;
  }
  .heading-s {
    font-size: 16px;
    line-height: 24px;
  }
  .body-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .body-2 {
    font-size: 16px;
    line-height: 24px;
  }
  .body-3 {
    font-size: 16px;
    line-height: 24px;
  }
  .title-1 {
    font-size: 16px;
    line-height: 24px;
  }
}

/* Laptops (min-width: 769px and max-width: 1200px) */
@media (min-width: 769px) and (max-width: 1150px) {
  .heading-3x {
    font-size: 52px;
    line-height: 68px;
  }
  .heading-2x {
    font-size: 40px;
    line-height: 60px;
  }
  .heading-x {
    font-size: 28px;
    line-height: 34px;
  }
  .heading-l {
    font-size: 20px;
    line-height: 36px;
  }
  .heading-n {
    font-size: 16px;
    line-height: 24px;
  }
  .heading-s {
    font-size: 14px;
    line-height: 20px;
  }
  .body-1 {
    font-size: 16px;
    line-height: 24px;
  }
  .body-2 {
    font-size: 18px;
    line-height: 22px;
  }
  .body-3 {
    font-size: 16px;
    line-height: 24px;
  }
  .title-1 {
    font-size: 16px;
    line-height: 24px;
  }
}
