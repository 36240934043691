.prof-image {
  border: 3px solid #c6dffb;
  border-radius: 160px;
  object-fit: cover;
  min-width: 25px;
}

.relative-time {
  font-size: 0.6rem;
}
.ant-modal-root {
  .ant-modal-mask {
    z-index: 1300;
  }
  .ant-modal-wrap {
    z-index: 1300;
  }
}
.askastudent-app-wrapper {
  background: var(--gray-200);
  .askastudent-general-form-wrapper {
    max-width: 43.75rem;
    margin: 0px auto;
    background: var(--white);
    border-radius: 0.9375rem;
    box-shadow: 1px 10px 25px hsla(0, 1%, 67%, 0.4);
    .title {
      font-size: 2.25rem;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 768px) {
  .askastudent-app-wrapper {
    .askastudent-general-form-wrapper {
      max-width: 25rem;
      .title {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .askastudent-app-wrapper {
    .askastudent-general-form-wrapper {
      max-width: 20rem;
    }
  }
}
