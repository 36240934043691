.app-header-filter {
  padding: 10px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow:
    0px 4px 6px -1px rgba(0, 0, 0, 0.2),
    0px 2px 4px -1px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  opacity: 1;
}

@media screen and (max-width: 576px) {
  .app-header-filter {
    padding: 10px;
    .filter-card {
      margin-left: 0 !important;
    }
  }
}
