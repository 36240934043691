/* The code is defining a SCSS variable called `` which is a map of color names and their
corresponding values. The color values are defined using CSS custom properties (variables). */

$colors: (
  primary: var(--primary),
  secondary: var(--app-secondary),
  black-text: var(--black-text-base), // Add more color mappings as needed,
);

@mixin generate-color-classes($color-map) {
  @each $color-name, $color-value in $color-map {
    .color-#{$color-name} {
      color: $color-value;
    }
    .bg-color-#{$color-name} {
      background-color: $color-value;
    }
  }
}

@include generate-color-classes($colors);
