@use 'colors.scss';

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.txt-capitalize {
  text-transform: capitalize;
}

.txt-center {
  text-align: center;
}
.txt-end {
  text-align: end;
}

.cursor-pointer {
  cursor: pointer;
}

.text-dotted {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
