.askastudent-loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background-color: #fff;
  // background: #fff url('../../assets/app/loader.svg') no-repeat center center;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-wrapper {
    path {
      stroke: var(--purple-normal) !important;
    }
  }
}

.askastudent-list-loader {
  min-height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: var(--purple-normal) !important; // Your custom color
  }
}
.askastudent-page-loader {
  min-height: 85dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
