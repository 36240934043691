.aas-admin-card-wrapper {
  color: var(--aas-palette-text-primary);
  background-color: #fff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 6px;
  background-image: none;
  box-shadow: 0px 3px 12px rgb(47 43 61 / 0.14);
}
.admin-user-avatar {
  gap: 1rem;
  display: flex;
  align-items: center;
  .name-initial-avatar {
    background-color: #eeedf0;
    color: rgb(47 43 61 / 0.9);
    font-size: 0.9375rem !important;
    line-height: 1.2;
  }
}
.user-content {
  display: flex;
  flex-direction: column;
  .body1 {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.46667;
    color: rgb(47 43 61 / 0.9);
  }
  .body2 {
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.53846154;
  }
}

.admin-general-list-wrapper {
  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    gap: 1rem;
    .search-input {
      max-width: 300px;
    }
    .ant-input-affix-wrapper {
      padding: 7.25px 14px;
    }
  }
}
