.otp-input-wrapper {
  width: 100%;
  height: auto;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.9375rem;
  }
  input {
    outline: 0.0938rem solid var(--black);
    border: none;
    max-width: 20%;
    width: 3.5rem !important;
    height: 3.75rem;
    text-align: center;
    border-radius: 0.3125rem;
    background: var(--gray-50);
    font-size: 1.5625rem;
    &:focus {
      outline: 0.0938rem solid var(--primary);
      outline-offset: 0.125rem;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      display: none;
    }
  }
}
