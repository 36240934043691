.ant-select-selection-overflow {
  flex-wrap: nowrap !important;
  overflow-y: hidden;
  overflow-x: scroll;
  .ant-select-selection-overflow-item {
    display: block;
  }
}
.ant-select-selection-overflow::-webkit-scrollbar {
  height: 0px;
}
.ant-select-selection-overflow::-webkit-scrollbar-track {
  height: 0px;
}
.ant-select-selection-overflow::-webkit-scrollbar-thumb {
  height: 0px;
  background: #333;
}
.ant-select-selector {
  height: 35px;
  overflow-y: hidden;
}
