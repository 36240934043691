.askastudent-txt-large {
  font-size: 2.2rem;
  font-weight: 600;
}

.txt-primary {
  color: var(--primary) !important;
}
.txt-light {
  color: var(--gray-400) !important;
}

.tiny-text {
  font-size: 0.9rem;
  font-weight: 500;
}

.txt-black {
  color: var(--black) !important;
}

@media only screen and (max-width: 992px) {
  .askastudent-txt-large {
    font-size: 2rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 768px) {
  .askastudent-txt-large {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .askastudent-txt-large {
    font-size: 1rem;
    font-weight: 400;
  }
}
