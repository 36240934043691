$sizes: 28;

@mixin border-radius-classes {
  @for $i from 0 through $sizes {
    $border-radius: $i * 0.25rem;
    /* margin #{$border-radius} */
    .br#{$i} {
      border-radius: $border-radius !important;
    }
  }
}
@include border-radius-classes;
